<template>
  <div id="app">
    <!-- <nav></nav> -->
    <!-- <keep-alive exclude="repaymentDetails,extend"> -->
    <router-view></router-view>
    <!-- </keep-alive> -->
    <!-- <router-view v-if="!$route.meta.keepAlive"></router-view> -->
  </div>
</template>

<script>
import { getCustomerServiceConfig } from "@/api/index";
export default {
  data() {
    return {};
  },
  async mounted() {
    // if (window.flutter_inappwebview.callHandler) {
    //   await window.flutter_inappwebview
    //     .callHandler("getBaseInfo", "")
    //     .then((result) => {
    //       console.log("app.vue:", result);
    //       localStorage.setItem("BaseInfo", JSON.stringify(result));
    //       localStorage.setItem("appCode", result.appCode);
    //       bsInfo = {
    //         appCode: Number(result.appCode),
    //         version: result.versionCode,
    //         userCode: Number(result.customerId),
    //       };
    //     })
    //     .catch((error) => {
    //       console.log(`getBaseInfo-error: "${error}"`);
    //       window.flutter_inappwebview.callHandler("loginOut");
    //     });
    // } else {
    //   await window.flutter_inappwebview
    //     .callHandler(
    //       "getBaseInfo",
    //       setTimeout(function () {}),
    //       JSON.stringify([""])
    //     )
    //     .then((result) => {
    //       console.log("app.vue:", result);
    //       localStorage.setItem("BaseInfo", JSON.stringify(result));
    //       localStorage.setItem("appCode", result.appCode);
    //       bsInfo = {
    //         appCode: Number(result.appCode),
    //         version: result.versionCode,
    //         userCode: Number(result.customerId),
    //       };
    //     });

    // }
    console.log("------app.vue");
    console.log(document.documentElement.style.fontSize,'$$$$$$$$$$$$$22222');
    let bsInfo;
    // let cId;
    // bsInfo= {
    //       appCode: 10056,
    //       version: "1",
    //       // userCode:15413,
    //       // userCode:15395,
    //       userCode:10056,

    //     };
    //     localStorage.setItem("BaseInfo", JSON.stringify(bsInfo));
    // TODO:测试数据
    let result = window.android.getBaseInfo();
    // let result = [
    //   JSON.stringify({
    //     baseUrl: "http://218.75.109.186:22222",
    //     appCode: "10062",
    //     versionCode: 1,
    //     versionName: "1.0.0",
    //     customerId: 13044,
    //     userUid: "10030_pymmtNBCAoijFnpfvgTn",
    //     token: "488EE17C1EA2A84A769746DCEDF0DEB3196170F9CCA32D05F6132AB93F85E930021C6C9B56B0471A9DA9DDDC93CCFA4632AED54D9BA54632D878E39DD4DB72F3",
    //     isGoogleTest: false,
    //   }),
    // ];
    console.log("app.vue:", result);

    try {
      if (result.length !== 0) {
        let resultlist = JSON.parse(result);
        localStorage.setItem("BaseInfo", JSON.stringify(resultlist));
        localStorage.setItem("appCode", resultlist.appCode);
        bsInfo = {
          appCode: Number(resultlist.appCode),
          version: resultlist.versionCode,
          userCode: Number(resultlist.customerId),
        };
      }
    } catch (error) {
      console.log(`getBaseInfo-error: "${error}"`);
      //window.flutter_inappwebview.callHandler("loginOut");
      window.android.loginOut();
    }

    /*await window.flutter_inappwebview
      .callHandler("getBaseInfo")
      .then((result) => {
        console.log("app.vue:", result);
        localStorage.setItem("BaseInfo", JSON.stringify(result));
        localStorage.setItem("appCode", result.appCode);
        bsInfo = {
          appCode: Number(result.appCode),
          version: result.versionCode,
          userCode: Number(result.customerId),
        };
      })
      .catch((error) => {
        console.log(`getBaseInfo-error: "${error}"`);
        //window.flutter_inappwebview.callHandler("loginOut");
        window.android.loginOut();
      });*/
    this.$store.commit("getFromApp", bsInfo);
    // console.log("2:", this.$store.state.flutter_baseInfo,this.$store.state.flutter_cId);
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
